<template>
  <div class="project__detail-container">
    <!-- @mouseenter="onSwiperMouseEnter"
    @mousemove="onSwiperMouseMove"
    @mouseleave="onSwiperMouseLeave" -->
    <p id="nmb" style="position: fixed;top:0;left:0;font-size:24px;z-index: 99;"></p>
    <template v-if="$bowser.mobile">
      <div class="detail4mobile__container">
        <div class="detail-header__container">
          <div class="project__info-location scrollObj" style="display:none;">
            <p>Nanchang｜南昌</p>
          </div>
          <div class="project__info-title scrollObj">
            <p class="title">{{ project.title.cn }}</p>
            <p class="val">{{ project.title.en }}</p>
          </div>
        </div>
        <div class="detail-main-body__container">
          <div
            v-for="(item, index) in slides"
            :key="index"
            class="info-block scrollObj"
            :class="[item.type]"
          >
            <img :src="item.val" alt="" />
          </div>
        </div>
        <div class="detail-footer__container" v-if="project.info.length > 0">
          <div class="ot-info__container">
            <div class="ot-info-row scrollObj" v-for="(info, index) in project.info" :key="index">
              <p class="title">{{ info.key }}</p>
              <p class="val">{{ info.val }}</p>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="detail__inner">
        <div class="project__base-info">
          <div class="info__title">
            <p class="en">{{ project.title.en }}</p>
            <p class="cn">{{ project.title.cn }}</p>
          </div>
          <div
            class="info__switch__bt no-click"
            @click="picsSwitch"
            :class="{
              normal: !allPictureActived,
              actived: allPictureActived,
              vHidden: subMenusOpen,
              pcHover: !$bowser.mobile
            }"
            ref="switchBt"
          >
            <div class="icon__wrapper">
              <svg class="bt__icon" xmlns="http://www.w3.org/2000/svg" viewBox="-2 -2 16 16">
                <rect fill="#000" width="2" height="2"></rect>
                <rect fill="#000" x="5" width="2" height="2"></rect>
                <rect fill="#000" x="10" width="2" height="2"></rect>
                <rect fill="#000" y="5" width="2" height="2"></rect>
                <rect fill="#000" x="5" y="5" width="2" height="2"></rect>
                <rect fill="#000" x="10" y="5" width="2" height="2"></rect>
                <rect fill="#000" y="10" width="2" height="2"></rect>
                <rect fill="#000" x="5" y="10" width="2" height="2"></rect>
                <rect fill="#000" x="10" y="10" width="2" height="2"></rect>
              </svg>
            </div>

            <p class="bt__text no-click">
              <span>all picture</span>
              <span>查看图片</span>
            </p>
          </div>
        </div>
        <div
          class="detail__main-type-container scrollObj"
          ref="swiperWrapper"
          @mouseenter="onDShow"
          @mousemove="onDMove"
          @mouseleave="onDHiden"
          @click="getChange"
        >
          <div
            class="directBt"
            :class="[cursorShow ? 'actived' : '']"
            ref="directBt"
            v-if="!$bowser.mobile"
          ></div>
          <swiper
            class="detail__swiper__wrapper"
            ref="detailSwiper"
            :options="swiperOptions"
            v-if="slides"
          >
            <swiper-slide
              class="detail__swiper-slide"
              v-for="(slide, index) in slides"
              :key="index"
            >
              <template v-if="slide.type === 'img'">
                <img class="slide__img" :src="slide.val" alt="" />
              </template>
            </swiper-slide>
          </swiper>
        </div>
        <transition
          @before-enter="onPicsBeforeEnter"
          @enter="onPicsEnter"
          @before-leave="onPicsBeforeLeave"
          @leave="onPicsLeave"
        >
          <div class="project__all-pictures-container" v-if="allPictureActived">
            <div class="all-pictures__inner">
              <div
                class="pic__item no-click"
                v-for="(pic, index) in slides"
                :key="index"
                @click="goSlide(index)"
              >
                <div class="item__inner no-click">
                  <img class="no-click" :src="pic.val" alt="" />
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div
        class="mouse__text"
        v-if="false"
        ref="mouseText"
        v-show="!allPictureActived && mouseTextShow"
      >
        <p class="text__title">work</p>
        <p class="text__depart">-</p>
        <div class="swiper__pagination"></div>
      </div>
      <div class="project__back no-click" v-if="!$bowser.mobile" @click="goBack">
        <p>back</p>
        <i></i>
      </div>
      <div class="logo_bottom scrollObj no-click" ref="blogo">
        <div class="project__info no-click">
          <p class="no-click">{{ project.title.cn }}</p>
          <p class="no-click">{{ project.title.en }}</p>
        </div>
        <div
          class="project__address no-click"
          @click="infoSwitch(true)"
          v-if="renderInfo.length > 0"
        >
          <p class="no-click">Description｜项目简介</p>
        </div>
      </div>
    </template>
    <transition @enter="onPicsEnter" @before-leave="onPicsBeforeLeave" @leave="onPicsLeave">
      <div class="project__side-detail-info__container no-click" v-if="infoShow">
        <div class="mask no-click" @click="infoSwitch(false)"></div>
        <div class="info__inner">
          <div class="info__main no-click">
            <div
              class="main__item"
              v-for="(item, index) in renderInfo"
              :key="index"
              :class="{
                hasRight: item.length == 2
              }"
            >
              <div
                class="main__side"
                :class="{
                  right: s % 2 === 1
                }"
                v-for="(side, s) in item"
                :key="s"
              >
                <p class="title">{{ side.key }}</p>
                <p class="val">{{ side.val }}</p>
              </div>
            </div>
          </div>
          <div class="info__footer no-click">
            <div class="footer__back no-click" @click="infoSwitch(false)">
              <p class="no-click">back to all</p>
              <i class="no-click line"></i>
            </div>
            <div class="footer__title no-click">
              <p class="cn no-click">{{ project.title.cn }}</p>
              <p class="en no-click">{{ project.title.en }}</p>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div class="mobile-back" id="mobileBack" v-if="$bowser.mobile" @click="goBack">
      <div class="icon-circle"></div>
      <p>back</p>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import { mapState } from 'vuex';
import GSAP from 'gsap';
import events from '@/utils/eventBus';
import { projectDetail } from '@/api/data';

export default {
  name: 'views-project-detail',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      project: {
        title: {
          en: '',
          cn: '',
        },
        info: [],
      },
      slides: null,
      allPictureActived: false,
      swiperOptions: {
        mousewheel: true,
        speed: 1000,
        loop: true,
        allowTouchMove: false,
        pagination: {
          el: '.swiper__pagination',
          type: 'fraction',
          formatFractionCurrent(number) {
            return number < 10 ? `0${number}` : number;
          },
          formatFractionTotal(number) {
            return number < 10 ? `0${number}` : number;
          },
        },
      },
      wrapperPosition: {
        x: 0,
        y: 0,
      },
      mousePosition: {
        x: 0,
        y: 0,
      },
      d: {
        x: 0,
        y: 0,
      },
      currentPos: {
        x: 0,
        y: 0,
      },
      speed: Math.PI,
      ease: 0.05,
      RFA: null,
      infoShow: false,
      mouseTextShow: true,
      cursorShow: false,
    };
  },
  computed: {
    ...mapState(['subMenusOpen']),
    renderInfo() {
      if (this.project.info.length > 0) {
        const temp = [];
        for (let i = 0; i < this.project.info.length; i += 2) {
          temp.push(this.project.info.slice(i, i + 2));
        }
        return temp;
      }
      return [];
    },
    id() {
      return Number(this.$route.params.id);
    },
    ww() {
      return window.innerWidth;
    },
  },
  watch: {
    id: {
      handler() {
        this.getDetail();
      },
      immediate: true,
    },
  },
  mounted() {
    const that = this;
    that.$nextTick(() => {
      if (!that.$bowser.mobile) {
        const wrapper = that.$refs.swiperWrapper;
        const bcr = wrapper.getBoundingClientRect();
        that.wrapperPosition.x = bcr.x;
        that.wrapperPosition.y = bcr.y;
        this.computedStyle();
      }
      events.$emit('pageMounted');
    });
  },
  methods: {
    onDShow() {
      this.cursorShow = true;
    },
    onDHiden() {
      this.cursorShow = false;
    },
    onDMove(e) {
      const that = this;
      const $xx = document.querySelector('.detail__main-type-container');
      that.mousePosition = {
        x: that.cursorShow ? e.clientX || e.pageX : 0,
        y: that.cursorShow ? (e.clientY || e.pageY) - $xx.offsetTop : 0,
      };
    },
    picsSwitch() {
      // this.cursorShow = this.allPictureActived;
      this.allPictureActived = !this.allPictureActived;
    },
    onPicsBeforeEnter() {},
    onPicsEnter(el, done) {
      done();
      setTimeout(() => {
        el.classList.add('enter');
      }, 10);
    },
    onPicsBeforeLeave(el) {
      el.classList.remove('enter');
    },
    onPicsLeave(el, done) {
      setTimeout(() => {
        done();
      }, 800);
    },
    turnLeft() {
      const { $swiper } = this.$refs.detailSwiper;
      $swiper.slidePrev();
    },
    turnRight() {
      const { $swiper } = this.$refs.detailSwiper;
      $swiper.slideNext();
    },
    goSlide(index) {
      const { $swiper } = this.$refs.detailSwiper;
      $swiper.slideTo(index + 1, 0);
      this.allPictureActived = false;
    },
    update() {
      const $obj = this.$refs.mouseText;
      const bcr = $obj.getBoundingClientRect();
      const { width, height } = bcr;
      const endX = this.mousePosition.x + 30;
      const endY = this.mousePosition.y + 20;

      let dx;
      let dy;

      let currentX = this.currentPos.x;
      let currentY = this.currentPos.y;

      if (!currentX || !currentY) {
        currentX = endX;
        currentY = endY;
      } else {
        dx = (endX - currentX) * 0.125;
        dy = (endY - currentY) * 0.125;
        if (Math.abs(dx) + Math.abs(dy) < 0.1) {
          currentX = endX;
          currentY = endY;
        } else {
          currentX += dx;
          currentY += dy;
        }
      }

      this.currentPos.x = currentX;
      this.currentPos.y = currentY;
      if (currentX + width < document.documentElement.clientWidth - 20) {
        $obj.style.left = `${currentX}px`;
      }
      if (currentY + height < window.innerHeight) {
        $obj.style.top = `${currentY}px`;
      }

      window.requestAnimationFrame(this.update.bind(this));
    },
    computedUpadte(pos, end) {
      // return t + (e - t) * i;
      return pos + (end - pos) * this.ease;
    },
    computedStyle() {
      const that = this;
      //   const offsetTop = window.scrollY - that.$refs.wrapper.offsetTop;
      const offsetTop = window.scrollY;
      if (that.$refs.directBt) {
        that.RFA = requestAnimationFrame(that.computedStyle);

        let dx;
        let dy;
        if (!that.d.x || !that.d.y) {
          that.d.x = that.mousePosition.x;
          that.d.y = that.mousePosition.y;
        } else {
          dx = (that.mousePosition.x - that.d.x) * 0.125;
          dy = (that.mousePosition.y - that.d.y) * 0.125;
          if (Math.abs(dx) + Math.abs(dy) < 0.1) {
            that.d.x = that.mousePosition.x;
            that.d.y = that.mousePosition.y;
          } else {
            that.d.x += dx;
            that.d.y += dy;
          }
        }

        const px = that.d.x - 20;
        const py = that.d.y + offsetTop - 15;

        if (px < this.ww / 2) {
          that.$refs.directBt.classList.add('left');
          that.$refs.directBt.classList.remove('right');
        } else {
          that.$refs.directBt.classList.add('right');
          that.$refs.directBt.classList.remove('left');
        }

        that.$refs.directBt.style.left = `${px}px`;
        that.$refs.directBt.style.top = `${py}px`;
      }
    },
    onSwiperMouseEnter() {
      // this.update();
    },
    onSwiperMouseMove(e) {
      this.mousePosition.x = e.clientX;
      this.mousePosition.y = e.clientY;

      const $obj = this.$refs.mouseText;
      const endX = this.mousePosition.x + 30;
      const endY = this.mousePosition.y + 20;
      const bcr = this.$refs.swiperWrapper.getBoundingClientRect();
      if (endX >= bcr.x && endY - 5 >= bcr.y && endY <= bcr.y + bcr.height) {
        GSAP.to($obj, {
          opacity: 1,
          duration: 0.5,
        });
      } else {
        GSAP.to($obj, {
          opacity: 0,
          duration: 0.5,
        });
      }
    },
    onSwiperMouseLeave() {
      // const $obj = this.$refs.mouseText;
      // window.cancelAnimationFrame();
    },
    infoSwitch(val) {
      this.cursorShow = !val;
      this.infoShow = val;
      this.mouseTextShow = !this.infoShow;
    },
    getDetail() {
      const that = this;
      projectDetail(that.id).then((res) => {
        const { data } = res;
        that.slides = data.project.content.map((item) => ({
          type: 'img',
          val: item,
        }));
        that.project.title = data.project.name;
        that.project.info = data.info;
        if (that.$bowser.mobile) {
          that.$nextTick(() => {
            that.pageInit();
          });
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    getChange(e) {
      if (!this.$bowser.mobile) {
        if (!e.target.classList.contains('no-click')) {
          const { $swiper } = this.$refs.detailSwiper;
          if (this.mousePosition.x < this.ww / 2) {
            $swiper.slidePrev();
          } else {
            $swiper.slideNext();
          }
        }
      }
    },
  },
};
</script>

<style></style>
